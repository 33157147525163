import logo from "./logo.svg";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import { Route, Routes } from "react-router-dom";
import ContactUs from "./pages/contactUs";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Dashboard />}></Route>
        <Route exact path="/contact_us" element={<ContactUs />} />
      </Routes>
    </>
  );

  // <Dashboard />;
}

export default App;
