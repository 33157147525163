import React, { useEffect } from "react";
import WOW from "wowjs";
const Dashboard = () => {
  useEffect(() => {
    const wow = new WOW.WOW({
      animateClass: "animated",
      offset: 100,
      callback: function (box) {
        console.log("WOW: animating <" + box.tagName.toLowerCase() + ">");
      },
    });
    wow.init();
  }, []);

  return (
    <>
      <header>
        <div className="header-section" id="myHeader">
          <div className="container">
            <div className="row">
              <div className="col-sm-3">
                <div className="header-logo">
                  <a href="index.html">
                    {" "}
                    <img src="images/logo.svg" alt="Logo" />
                  </a>
                </div>
              </div>
              <div className="col-sm-9">
                <div className="header-menu">
                  <div className="menu-navbar">
                    <div className="navbar-header">
                      <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#myNavbar"
                      >
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                      </button>
                    </div>
                    <div
                      className="collapse navbar-collapse iphonNav nav-top clearfix navigation"
                      id="myNavbar"
                      style={{ display: "block" }}
                    >
                      <div className="mobile-logo" style={{ display: "none" }}>
                        <a href="#">
                          {" "}
                          <img src="images/logo.svg" alt="Logo" />
                        </a>
                      </div>
                      <div className="menu-top-menu-container">
                        <ul>
                          <li>
                            <a href="#">Overview </a>
                          </li>
                          <li>
                            <a href="#">ONDC</a>
                          </li>
                          <li>
                            <a href="#"> Press Reference</a>
                          </li>
                          <li>
                            <a href="#">Pricing</a>
                          </li>
                          <li>
                            <a href="#">Sign In</a>
                          </li>
                          <li>
                            <a href="/contact_us">Contact Us</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="header-btn">
                    <a className="theme-btn" href="#">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="home-banner">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="banner-caption">
                <div className="hero-title">
                  Upgrade Your Business
                  <small>With</small>
                  <strong>ONDC Seller</strong>
                </div>
                <p>
                  Easy &amp; Affordable <br /> Business &amp; Retail Management
                  Platform
                </p>
                <div className="hero-btn">
                  <a className="theme-btn" href="#">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="banner-img wow fadeInRight">
                <img src="images/banner-img.png" alt="bannerimg" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="business-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="business-img wow fadeInRight">
                <img src="images/business-img.png" alt="bannerimg" />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="business-caption wow fadeInLeft">
                <p>
                  <strong>ONDC Seller</strong> One offers GST compliant, easy to
                  use complete POS solution.Run your retail business, one or
                  many - from anywhere.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Management-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="Management-caption wow fadeInLeft">
                <h4>Hassle Free Inventory Management</h4>
                <p>Unlimited products, minimum difficulty.</p>
                <div className="list-icon">
                  <ul>
                    <li className="clearfix">
                      <figure>
                        <img src="images/text-icon.png" />
                      </figure>{" "}
                      <figcaption>
                        SmartEntry - Access to over 1.5 Crore product
                        information
                      </figcaption>
                    </li>
                    <li className="clearfix">
                      <figure>
                        <img src="images/copy-icon.png" />
                      </figure>{" "}
                      <figcaption>Assisted HSN/SAC Coding</figcaption>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="Management-img wow fadeInRight">
                <img src="images/Management-img.png" alt="bannerimg" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="RealTime-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="Management-img wow fadeInRight">
                <img src="images/RealTime.png" alt="RealTime" />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="Management-caption wow fadeInLeft">
                <h4>Run Your Business - Real Time</h4>
                <p>
                  Instant tracking of sales, reports, purchases &amp; all other
                  activities.
                </p>
                <div className="list-icon">
                  <ul>
                    <li className="clearfix">
                      <figure>
                        <img src="images/text-icon.png" />
                      </figure>{" "}
                      <figcaption>
                        Multiple business with single account.
                      </figcaption>
                    </li>
                    <li className="clearfix">
                      <figure>
                        <img src="images/copy-icon.png" />
                      </figure>{" "}
                      <figcaption>Multi location/store management.</figcaption>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* asdfasdasdfsdaf */}

      <footer className="footer-section">
        <div className="Singhtek-section">
          <div className="container">
            <div className="row Singhtek-box">
              <div className="col-sm-4">
                <div className="Singhtek-logo">
                  <a href="#">
                    <img src="images/s-logo.svg" alt="Singhtek" />
                  </a>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="Singhtek-info">
                  <p>
                    Singhtek BizGroup: Your Trusted Business Consulting &amp;
                    Management Partner in India and Abroad. We are here to
                    assist you in every manner possible
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <div className="footer-info">
                <h5>About us</h5>
                <p>
                  We workfor Payment Industries, Payment Services, Banking
                  Solutions, Software Development, HR Solution, Co- Working
                  Space Management, Cybersecurity and Cybercrime Investigation
                  for Fintech Industries. Call us for support.
                </p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="footer-info menuf">
                <h5>Useful Links</h5>
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">About Company</a>
                  </li>
                  <li>
                    <a href="#">Service</a>
                  </li>
                  <li>
                    <a href="#">Career</a>
                  </li>
                  <li>
                    <a href="#">Media</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="footer-info">
                <h5>Contact </h5>
                <ul>
                  <li>
                    <a href="#">Partner</a>
                  </li>
                  <li>
                    <a href="#">Business Enquiry</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#">Terms &amp; Condition</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="footer-info">
                <h5>Address </h5>
                <p>
                  Address : Royal Emporia - Plot NO. 12, Swaroop Colony, Tagore
                  Nagar, DCM, Ajmer Road, JAIPUR - 302021
                </p>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="Copyrighted">
                <div className="row">
                  <div className="col-sm-8">
                    <p>
                      Copyrighted &amp; Designed by Fintech, Business and IT
                      Consulting Firm in India- Singhtek BizGroup
                    </p>
                  </div>
                  <div className="col-sm-4">
                    <div className="social-media">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin-in" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Dashboard;
